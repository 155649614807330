import { defineComponent } from '@vue/composition-api';

import PageNodeMain from '@/components/PageNodeMain';
import PageNodeSub from '@/components/PageNodeSub';
import Card from '@/components/Card';

import GuestboardWishes from '@/components/Article/GuestboardWishes';
//import GuestboardList from '@/components/Article/GuestboardList';
import CommentList from '@/components/Article/CommentList';
import CommentPublish from '@/components/Article/CommentPublish';
import GuestbookRank from '@/components/Article/GuestbookRank';

export default defineComponent({
	name: 'GuestBoard',
	components: {
		PageNodeMain,
		PageNodeSub,
		Card,
		GuestboardWishes,
		CommentList,
		CommentPublish,
		GuestbookRank,
	},

	setup() {
		return {};
	},
});
