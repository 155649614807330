import { defineComponent, Ref, ref } from '@vue/composition-api';

import FetchData from '@/components/FetchData';

import store from '@/store';

interface RankData {
	onlyid: string;
	nickname: string;
	avatar: string;
	count: number;
}

export default defineComponent({
	components: { FetchData },
	setup() {
		const rankData: Ref<RankData[]> = ref([
			{ onlyid: 'aaaa', nickname: '这是一个昵称', avatar: store.state.limitImgUrl, count: -1 },
			{ onlyid: 'bbbb', nickname: '这是一个昵称123', avatar: store.state.limitImgUrl, count: -1 },
			{ onlyid: 'cccc', nickname: '这是一个昵称112233', avatar: store.state.limitImgUrl, count: -1 },
		]);
		const touchData = (data: RankData[]) => {
			rankData.value = data;
		};
		return { rankData, touchData };
	},
});
