import { defineComponent } from '@vue/composition-api';

import GIF1 from './img/1.gif';
import GIF2 from './img/2.gif';
import GIF3 from './img/3.gif';
import GIF4 from './img/4.gif';
import GIF5 from './img/5.gif';
import GIF6 from './img/6.gif';
import GIF7 from './img/7.gif';
import GIF8 from './img/8.gif';
import GIF9 from './img/9.gif';
import GIF10 from './img/10.gif';
import GIF11 from './img/11.gif';
import GIF12 from './img/12.gif';
import GIF13 from './img/13.gif';
import GIF14 from './img/14.gif';
import GIF15 from './img/15.gif';

export default defineComponent({
    name: 'GuestboardWishes',
    props: {},
    components: {},
    setup() {
        return {
            randomGIF: [
                GIF1,
                GIF2,
                GIF3,
                GIF4,
                GIF5,
                GIF6,
                GIF7,
                GIF8,
                GIF9,
                GIF10,
                GIF11,
                GIF12,
                GIF13,
                GIF14,
                GIF15,
            ],
        };
    },
});
